<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-dialog v-model="dialog" width="700" transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class=" blue--text mb-2 accent-2" outlined v-bind="attrs" v-on="on"
                            @click="getCategoriesReference()"><v-icon>mdi-plus</v-icon> زیادکردنی خەرجی</v-btn>
                    </template>
                    <v-card>
                        <v-toolbar dark outlined :class="(isUpdate ? 'orange accent-2' : 'blue accent-2')" elevation="1">
                            <v-btn icon @click="dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn text @click="isUpdate ? update() : create()">
                                    <v-icon>{{ isUpdate ? 'mdi-check' : 'mdi-plus' }}</v-icon> {{ isUpdate ? 'نوێکردنەوەی
                                                                        خەرجی' :
                                    'زیادکردنی خەرجی' }} </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-form ref="expense">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-autocomplete :items="categoryReference" item-text="category_name"
                                            item-value="category_id" dense outlined label="جۆر"
                                            v-model="expense.category_id"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-text-field @focus="$event.target.select()" :rules="rules.numberRule"
                                            prepend-inner-icon="mdi-currency-usd" dense label="بڕی" outlined
                                            v-model="expense.amount" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-text-field @focus="$event.target.select()" :rules="rules.stringRule" dense
                                            label="خەرجکرا بۆ" outlined v-model="expense.detail"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field @focus="$event.target.select()" v-model="expense.date"
                                                    label="بەرواری" prepend-inner-icon="mdi-calendar" readonly
                                                    v-bind="attrs" v-on="on" outlined dense></v-text-field>
                                            </template>
                                            <v-date-picker v-model="expense.date" @input="menu2 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card>
                </v-dialog>
                <v-card>
                    <v-data-table :headers="headers" :items="expenses" hide-default-footer :items-per-page="20"
                        :loading="loading" no-data-text="هیچ خەرجییەک نییە">
                        <template v-slot:top>
                            <v-text-field @focus="$event.target.select()" v-model="table.search" label="گەڕان"
                                class="mx-4"></v-text-field>
                        </template>
                        <template v-slot:[`item.amount`]="{ item }">
                            {{ '$' + item.amount.toLocaleString() }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="75" id="icon">
                                <v-icon large class="ml-2" v-if="hover" color="blue accent-2" id="icon"
                                    @click="expense = { ...removeProperty(item, ['member_name', 'category_name']) }; dialog = true; getCategoriesReference()">mdi-pencil</v-icon>
                                <v-icon large class="ml-2" v-else>mdi-pencil</v-icon>
                            </v-hover>
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon">
                                <v-icon large class="ml-2" v-if="hover" color="red accent-2" id="icon"
                                    @click="remove(item.expense_id)">mdi-delete</v-icon>
                                <v-icon large class="ml-2" v-else>mdi-delete</v-icon>
                            </v-hover>
                        </template>
                    </v-data-table>
                    <v-pagination color="orange accent-2" :length="Math.ceil(table.numberOfExpenses / 20)"
                        v-model="table.page"></v-pagination>
                </v-card>
            </v-col>
        </v-row>
        <notification />
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            table: {
                page: 1,
                numberOfExpenses: 0,
                search: ''
            },
            dialog: false,
            menu2: false,
            memberReference: [],
            categoryReference: [],
            expense: {
                expense_id: 0,
                category_id: 0,
                amount: 0,
                detail: '',
                member_id: 0,
                date: new Date().toISOString().split("T")[0]
            },
            loading: false,
            expenses: [],
            headers: [
                { text: 'بڕی', value: 'amount', align: 'center' },
                { text: 'خەرجکرا بۆ', value: 'detail', align: 'center' },
                { text: 'جۆر', value: 'category_name', align: 'center' },
                { text: 'بەرواری', value: 'date', align: 'center' },
                { text: 'کردارەکان', value: 'actions', align: 'center' },
            ],
            rules: {
                stringRule: [
                    (v) => {
                        return (v != '' || 'تکایە ئەم خانەیە پڕبکەرەوە')
                    }
                ],
                numberRule: [
                    (v) => {
                        return (v >= 0 || 'ژمارەکە پێویستە لە 0 زیاتربێت')
                    }
                ]
            }
        }
    },
    methods: {
        async getExpenses() {
            try {
                this.loading = true
                const { data } = await this.axios.get('/expense', { params: { page: this.table.page, search: this.table.search } })
                data.expenses.forEach(expense => {
                    expense.date = new Date(expense.date).toISOString().split("T")[0]
                })
                this.expenses = data.expenses
                this.table.numberOfExpenses = data.count
                this.loading = false
            } catch (error) {
                console.log(error);
                this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
            }
        },
        async getCategoriesReference() {
            try {
                const { data } = await this.axios.get('/expense_category/forReference')
                this.categoryReference = data
            } catch (error) {
                this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
            }
        },
        async create() {
            if (this.$refs.expense.validate()) {
                try {
                    await this.axios.post('/expense', this.expense)
                    this.reset()
                    this.getExpenses()
                } catch (error) {
                    this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
                }
            }
        },
        async update() {
            if (this.$refs.expense.validate()) {
                try {
                    await this.axios.patch('/expense/' + this.expense.expense_id, this.expense)
                    this.getExpenses()
                    this.reset()
                    this.dialog = false
                } catch (error) {
                    this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
                }
            }
        },
        remove(id) {
            this.$confirm('ئایا دڵنیایی لە سڕینەوەی ئەم خەرجییە؟', 'دڵنیابوونەوە', 'question').then(async () => {
                try {
                    await this.axios.delete('/expense/' + id)
                    this.getExpenses()
                } catch (error) {
                    this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
                }
            })
        },
        reset() {
            this.expense = {
                expense_id: 0,
                category_id: 0,
                amount: 0,
                detail: '',
                date: new Date().toISOString().split("T")[0]
            }
            this.$refs.expense.resetValidation();
        },
        removeProperty({ ...obj } = {}, fields) {
            for (let i = 0; i < fields.length; i++)  delete obj[fields[i]]
            return obj
        }
    },
    created() {
        this.getExpenses()
    },
    watch: {
        dialog(val) {
            if (!val) {
                this.memberReference = []
                this.categoryReference = []
                this.reset()
            }
        },
        'table.search': function () {
            this.getExpenses()
        },
        'table.page': function () {
            this.getExpenses()
        }
    },
    computed: {
        isUpdate() {
            return this.expense.expense_id
        }
    }
}
</script>

<style></style>